import React from "react";
import Layout from "./components/Layout";

const Hours = () => {
  return (
    <Layout>
      <div className="bg-[#FFFF00] p-8 min-h-screen flex flex-col items-center">
        <h2 className="text-5xl font-bold mb-4">Hours & Location</h2>

        <div className="text-center mb-4">
          <p className="text-semibold text-2xl mb-2">
            Monday - Thursday: 10:30 AM - 10:00 PM
          </p>
          <p className="text-semibold text-2xl mb-2">
            {" "}
            Friday: 10:30 AM - MIDNIGHT{" "}
          </p>
          <p className="text-semibold text-2xl mb-2">
            Saturday: 11:00 AM - MIDNIGHT
          </p>
          <p className="text-semibold text-2xl mb-2">
            {" "}
            Sunday: 4:00 PM - 10:00 PM{" "}
          </p>
          <p className="font-bold text-semibold text-2xl mt-2">
            2002 Luxstone Blvd SW Unit 108, Airdrie, AB T4B 2W6
          </p>
        </div>

        <div className="w-full flex justify-center mt-2">
          <div className="w-full max-w-5xl bg-white rounded-lg shadow-lg p-4">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2496.1438752567324!2d-114.02056682337171!3d51.2716706717607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53715fa1485bc847%3A0x1d4af59538898cd6!2sChicago%20Deep%20Dish%20Pizza!5e0!3m2!1sen!2sca!4v1724541747087!5m2!1sen!2sca&maptype=roadmap&style=feature:all|element:labels|visibility:off&style=feature:landscape|element:geometry.fill|color:0xf2efe9&style=feature:road|element:geometry.stroke|color:0x9e9e9e"
              width="100%"
              height="500"
              title="map"
              style={{
                border: 0,
                borderRadius: "12px",
                filter: "grayscale(10%) contrast(120%) brightness(90%)",
              }}
              allowFullScreen={false}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Hours;
