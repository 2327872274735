import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Nav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="h-full p-8 bg-black text-white flex flex-col overflow-auto justify-between">
      <div className="text-center cursor-pointer" onClick={() => navigate("/")}>
        <img src="dd1.jpg" alt="logo" className="h-1/2 w-full mt-8 sm:mt-4" />
        <p className="text-sm mt-2 sm:mt-4 sm:text-base ">
          2002 Luxstone Blvd SW Unit 108, Airdrie, AB T4B 2W6
        </p>
        <p className="mt-2 sm:mt-1">(403) 948-1048</p>
      </div>
      <div className="mt-6 mb-auto sm:mt-8">
        <nav className="text-lg font-bold text-center sm:mt-6 space-y-4 sm:space-y-4">
          <Link
            to="/Order"
            className="bg-white text-gray-900 rounded-md font-bold py-1 px-1 mt-2 mb-6 w-full block text-center sm:mt-2 sm:py-2 sm:px-3 hover:bg-red-600"
          >
            Airdrie Favorite Slice Joint 🍕
          </Link>
          <Link
            to="/Menu"
            className={`block border-b border-gray-700 pb-2 ${
              location.pathname === "/Menu"
                ? "text-red-600"
                : "hover:text-red-600"
            } sm:pb-3`}
          >
            MENU
          </Link>
          <Link
            to="/Hours"
            className={`block border-b border-gray-700 pb-2 ${
              location.pathname === "/Hours"
                ? "text-red-600"
                : "hover:text-red-600"
            } sm:pb-3`}
          >
            HOURS & LOCATION
          </Link>
          <Link
            to="/Merch"
            className={`block border-b border-gray-700 pb-2 ${
              location.pathname === "/Merch"
                ? "text-red-600"
                : "hover:text-red-600"
            } sm:pb-3`}
          >
            MERCH
          </Link>
          <Link
            to="/About"
            className={`block border-b border-gray-700 pb-2 ${
              location.pathname === "/About"
                ? "text-red-600"
                : "hover:text-red-600"
            } sm:pb-3`}
          >
            ABOUT
          </Link>
          <Link
            to="/Contact"
            className={`block border-b border-gray-700 pb-2 ${
              location.pathname === "/Contact"
                ? "text-red-600"
                : "hover:text-red-600"
            } sm:pb-3`}
          >
            CONTACT
          </Link>
        </nav>
      </div>
      <div className="flex justify-center mt-2 space-x-4">
        <a href="#">
          <i className="fab fa-facebook-f text-white"></i>
        </a>
        <a href="#">
          <i className="fab fa-instagram text-white"></i>
        </a>
        <a href="#">
          <i className="fas fa-envelope text-white"></i>
        </a>
      </div>
    </div>
  );
};

export default Nav;
