import React, { useState } from "react";
import Layout from "./components/Layout";

const HomeScreen = () => {
  const images = [
    "/finalEdits/11.jpg",
    "/finalEdits/2.jpg",
    "/finalEdits/13.jpg",
    "/finalEdits/54.jpg",
    "/finalEdits/57.jpg",
    "/finalEdits/15.jpg",
    "/finalEdits/39.jpg",
    "/finalEdits/10.jpg",
    "/finalEdits/7.jpg",
    "/finalEdits/41.jpg",
    "/finalEdits/56.jpg",
    "/finalEdits/74.jpg",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1,
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1,
    );
  };

  return (
    <Layout>
      <div className="relative">
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          className="w-full h-screen object-cover"
        />

        <div className="absolute inset-0 flex justify-between items-center">
          {/* Left Arrow */}
          <button
            onClick={handlePrev}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-4 rounded-full hover:bg-opacity-75 focus:outline-none"
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              borderRadius: "50%",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>

          {/* Right Arrow */}
          <button
            onClick={handleNext}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-4 rounded-full hover:bg-opacity-75 focus:outline-none"
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              borderRadius: "50%",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>

        <div className="absolute bottom-0 left-0 w-full p-8 text-center text-white bg-black bg-opacity-50">
          <p>@CDDP_Pizza</p>
        </div>
      </div>
    </Layout>
  );
};

export default HomeScreen;
